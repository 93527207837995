import React from 'react'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from './PartnersComponents.module.scss'
import PartnersBlock from './components/PartnersBlock'

class PartnersComponents extends React.Component {
  state = { intl: this.props.intl.messages.partnersPage }

  static propTypes = {
    images: PropTypes.array,
    intl: PropTypes.object,
  }

  render() {
    const { intl } = this.state
    return (
      <React.Fragment>
        <div className={classnames(style.basicBlock)}>
          <h1 dangerouslySetInnerHTML={{ __html: intl.heading }} />
          <PartnersBlock info={intl.partnersBlock} images={this.props.images} />
        </div>
      </React.Fragment>
    )
  }
}

export default injectIntl(PartnersComponents)
