import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './FormPartners.module.scss'

class FormPartners extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      tel: '',
      email: '',
      text: '',
      showMessage: false,
      error: false,
      data: props.intl.messages.partnersPage.form,
      textLength: 0
    }
    this.modalRef = React.createRef()
  }

  static propTypes = {
    onClick: PropTypes.func,
    intl: PropTypes.object,
    showForm: PropTypes.bool,
    amoForm: PropTypes.bool,
  }
  static defaultProps = {
    onClick: () => console.log('Empty function'),
    amoForm: false,
  }

  formRef = React.createRef()

  componentDidMount() {
    if (this.props.amoForm && this.formRef && this.formRef.current) {
      let script1 = document.createElement('script')
      let script2 = document.createElement('script')

      /*if (this.props.intl.locale === 'ru') {
        script1.innerHTML = 'var amo_forms_params = {"id":499693,"hash":"63ef6cbe28f4338f15f27e0b3b51dbff","locale":"ru"};'
      } else {
        script1.innerHTML = 'var amo_forms_params = {"id":503080,"hash":"bceabfdce6e85408f5b3438075bf7fa1","locale":"ru"};'
      }*/

      if (this.props.intl.locale === 'ru') {
        script1.innerHTML = '!function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"600798",hash:"48e37964ba1616a69601886cadeefd8e",locale:"ru"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");'
      } else {
        script1.innerHTML = '!function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"600801",hash:"6c50c79a75891cb404911ab3d6fda69d",locale:"ru"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");'
      }

      script2.id = this.props.intl.locale === 'ru' ? 'amoforms_script_600798' : 'amoforms_script_600801'
      script2.async = true
      script2.charset = 'utf-8'
      script2.src = this.props.intl.locale === 'ru'
        ? 'https://forms.amocrm.ru/forms/assets/js/amoforms.js?1667822112'
        : 'https://forms.amocrm.ru/forms/assets/js/amoforms.js?1667822203'

      this.formRef.current.appendChild(script1)
      this.formRef.current.appendChild(script2)
    }
  }

  handleChange = event => {
    event.persist()

    this.setState(() => {
      if (event.target.name === 'text') {
        const text = event.target.value.length > 250 ? event.target.value.substring(0, 250) : event.target.value
        return { [event.target.name]: text, textLength: text.length }
      } else {
        return { [event.target.name]: event.target.value }
      }
    })
  }

  handleSubmit = async event => {
    event.preventDefault()

    const result = await addToMailchimp(this.state.email, {
      FNAME: this.state.name,
      TEXT: this.state.text,
      PHONE: this.state.tel,
    })

    //ym('reachGoal','partner')

    this.setState(() => {
      return {
        showMessage: true,
        message: result.result == 'error' ? result.msg : this.state.message,
        error: result.result == 'error' ? true : false,
      }
    })
  }

  clearForm = () => {
    this.setState(() => {
      return {
        name: '',
        tel: '',
        email: '',
        text: '',
        showMessage: false,
        error: false,
      }
    })
  }

  render() {
    const {
      name,
      tel,
      email,
      text,
      showMessage,
      data,
      textLength
    } = this.state
    const { amoForm } = this.props
    return (
      <React.Fragment>
        <div ref={this.modalRef} className={classnames(style.subscription)}>
          <div className={classnames(style.innerContainer)}>
            {showMessage ? (
              <React.Fragment>
                <div className={style.message}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.success,
                    }}
                  />
                  <FontAwesomeIcon onClick={this.clearForm} icon={['fas', 'redo']} />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h2 className={style.formWrap}
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                />
                {amoForm ? <section ref={this.formRef}></section> : (
                  <form onSubmit={this.handleSubmit}>
                    <div className={style.formBox}>
                      <div className={style.labelGroup}>
                        <label className={style.label}>
                          <span>{data.nameLabel}</span>
                          <input
                            onChange={this.handleChange}
                            type="text"
                            name="name"
                            value={name}
                            placeholder={data.namePlace}
                            className={style.formInput}
                            required
                          />
                        </label>
                        <label className={style.label}>
                          <span>{data.emailLabel}</span>
                          <input
                            onChange={this.handleChange}
                            type="email"
                            name="email"
                            value={email}
                            placeholder={data.emailPlace}
                            className={style.formInput}
                            required
                          />
                        </label>
                        <label className={style.label}>
                          <span>{data.tel}</span>
                          <input
                            onChange={this.handleChange}
                            type="text"
                            name="tel"
                            value={tel}
                            placeholder="+7 (495) 995-58-72"
                            className={style.formInput}
                          />
                        </label>
                      </div>
                      <div className={style.formColumn}>
                        <div className={style.textareaBox}>
                        <textarea
                          placeholder={data.textareaPlace}
                          className={style.textarea}
                          onChange={this.handleChange}
                          value={text}
                          name="text"
                        />
                          <output>{textLength} / 250</output>
                        </div>
                        <input type="submit" value={data.submitBtn} className={style.formSubmit}/>
                      </div>
                    </div>
                    <div className={classnames(style.labelGroup, style.labelSmartphone)} />
                  </form>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default injectIntl(FormPartners)
