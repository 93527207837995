import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import DefaultLayout from '../layouts/default/defaultLayout'
import PartnersComponents from '../layouts/partners/PartnersComponents'
import { withIntl } from '../i18n'

class PartnersPage extends React.Component {
  state = { title: this.props.intl.messages.partnersPage.title }

  static propTypes = {
    data: PropTypes.object,
    intl: PropTypes.object,
  }

  render() {
    return (
      <DefaultLayout>
        <Helmet
          meta={[{ charset: 'utf-8' }]}
          title={this.state.title}
        />
        <PartnersComponents images={this.props.data['PartnersImgs_' + this.props.intl.locale].edges} />
      </DefaultLayout>
    )
  }
}

export const query = graphql`
  query allPartnersPageImgsQuery {
    PartnersImgs_ru: allFile(
      sort: { order: ASC, fields: [name] }, 
      filter: { relativeDirectory: {regex: "images/partners/ru/" } }
    ) {
      edges {
        node {
          name,
          publicURL
        }
      }
    }
    PartnersImgs_en: allFile(
      sort: { order: ASC, fields: [name] }, 
      filter: { relativeDirectory: {regex: "images/partners/en/" } }
    ) {
      edges {
        node {
          name,
          publicURL
        }
      }
    }
  }
`

export default withIntl(injectIntl(PartnersPage))
