import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from '../PartnersComponents.module.scss'
import FormPartners from '../../../components/form-partners/FormPartners'

class PartnersBlock extends PureComponent {
  static propTypes = {
    info: PropTypes.array,
    images: PropTypes.array,
  }

  render() {
    const { info, images } = this.props
    let division = Math.floor(info.length / 2)
    let logos = {};
    images.map(x => logos[x.node.name] = x.node.publicURL)
    return (
      <ul>
        {info.map((x, i) => {
          const showForm = i === division
          return (
            <React.Fragment key={`item-${i}`}>
              {showForm ? (
                <li className={style.innerBlock + ' ' +  style.innerBlockForm}>
                  <FormPartners amoForm />
                </li>
              ) : null }
              <li className={style.innerBlock}>
                {x.imgLink ? (
                  <a
                    href={x.imgLink}
                    className={style.partnersLogo}
                    style={logos[x.imgName] ? {} : { display: 'none' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={logos[x.imgName] || ''} alt={x.name} />
                  </a>
                ) : (
                  <div className={style.partnersLogo} style={logos[x.imgName] ? {} : {display: 'none'}}>
                    <img src={logos[x.imgName] || ''} alt={x.name} />
                  </div>
                )}
                <div className={style.partnersInfo}>
                  <h2 className={style.partnersName}
                      dangerouslySetInnerHTML={{
                        __html: x.name,
                      }}
                  />
                  <div className={style.partnersDescription} style={x.description ? {} : {display: 'none'}}
                       dangerouslySetInnerHTML={{
                         __html: x.description || ''
                       }}
                  />
                </div>
              </li>
            </React.Fragment>
          )
        })}
      </ul>
    )
  }
}

export default injectIntl(PartnersBlock)
